import { PageLoaderWithNav } from '@/components/PageLoaderWithNav';
import { TopNav } from '@/components/TopNav';
import { Breadcrumb, BreadcrumbItem, BreadcrumbList, BreadcrumbSeparator } from '@/components/ui/breadcrumb';
import { Button } from '@/components/ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useFdk, usePlainFdk } from '@/fdk';
import { getFlagEmoji, languages } from '@/utils';
import { t } from 'i18next';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import StationContentForm from './StationContentForm';

export function StationContent() {
  const fdk = usePlainFdk();
  const [pending, setPending] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { stationID, guideID, shortID } = useParams();
  const { i18n } = useTranslation('translation');
  const currentLang = i18n.language;

  const {
    data: station,
    mutate,
    isLoading,
  } = useFdk({
    action: 'getEntry',
    model: 'guide_tour_stations',
    entryID: stationID,
    options: {
      _fields: ['chapters', 'audio_map'],
    },
  });

  const { data: tour } = useFdk({
    action: 'getEntry',
    model: 'guide_tours',
    entryID: guideID,
    options: {
      _fields: ['languages'],
    },
  });

  const currentLanguages = useMemo(() => tour?.languages?.split(',') ?? [], [tour]);

  const chapterID = useMemo(
    () => station?.audio_map?.[searchParams.get('locale') ?? currentLanguages[0]]?.id,
    [station, searchParams, currentLanguages],
  );

  const { data: content, isLoading: isLoadingContent } = useFdk(
    chapterID && {
      action: 'getEntry',
      model: 'chapter',
      entryID: chapterID,
    },
  );

  const langs = useMemo(() => languages?.[currentLang] ?? [], [currentLang, languages]);

  const getLanguage = useCallback(
    (lang: string) => {
      const found = langs.find((l) => l.language === lang);

      return {
        language: lang,
        ...(found ?? {}),
        flag: getFlagEmoji(found?.countryCode ?? lang),
      };
    },
    [langs],
  );

  const create = useCallback(
    async (locale: string) => {
      setPending(true);
      let {
        items: [project],
      } = await fdk.model('project').entryList({
        name: 'Audio Guides',
      });
      if (!project) {
        project = await fdk.model('project').createEntry({
          name: 'Audio Guides',
          description: 'Audio Guides',
          content: {},
        });
      }

      const { id } = await fdk.model('chapter').createEntry({
        name: `${station.title[locale]} (${getLanguage(locale).name})`,
        description: `${station.description[locale]} (${getLanguage(locale).name})`,
        state: 'draft',
        language: locale,
        project: project.id,
        content: {
          voiceID: import.meta.env.VITE_DEFAULT_VOICE_ID,
          sections: [
            {
              type: 'text',
              pause: 0,
              title: 'Sektion 1',
              content: '',
            },
          ],
        },
      });
      await fdk.model('guide_tour_stations').editEntry(station.id, {
        audio_map: {
          ...station.audio_map,
          [locale]: {
            id,
            type: 'chapter',
          },
        },
        chapters: [...(station.chapters ?? []), id],
      });
      await mutate();
      setPending(false);
    },
    [station],
  );

  if (isLoading) {
    return <PageLoaderWithNav />;
  }

  return (
    <>
      <TopNav>
        <div className="flex grow items-center justify-between space-x-4">
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <Link to={`/${shortID}/guide/${guideID}`}>{t('header.stations')}</Link>
              </BreadcrumbItem>
              <BreadcrumbSeparator />

              <BreadcrumbItem>{station?.title[searchParams.get('locale') ?? currentLanguages[0]]}</BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
      </TopNav>
      <Tabs defaultValue={searchParams.get('locale') ?? currentLanguages[0]} key={currentLanguages[0]}>
        <TabsList>
          {currentLanguages.map((lang: any, i) => {
            const first = i === 0;
            const { name, flag, language } = getLanguage(lang);

            return (
              <TabsTrigger
                key={language}
                className="gap-3 flex rounded-lg data-[state=active]:!bg-secondary data-[state=active]:!text-dark"
                value={language}
                onClick={() =>
                  setSearchParams((old) => {
                    old.set('locale', lang);

                    return old;
                  })
                }
              >
                <span className="text-sm">{flag}</span>
                {name}{' '}
                {first && <div className="text-xs flex gap-2 ">{t('audioGuide.detail.tourDefaultLanguage')}</div>}
              </TabsTrigger>
            );
          })}
        </TabsList>
        {currentLanguages.map((lang) => (
          <TabsContent key={lang} value={lang} className="flex flex-col gap-6">
            {isLoadingContent || content ? (
              <StationContentForm projectID={content?.project} chapterID={content?.id} />
            ) : (
              <div className="flex flex-col gap-6">
                <h2 className="text-2xl font-semibold">{t('audioGuide.detail.chapterNotCreated')}</h2>
                <p>{t('audioGuide.detail.chapterNotCreatedDescription')}</p>
                <Button onClick={() => create(lang)} disabled={pending}>
                  {t('audioGuide.detail.chapterCreate')}{' '}
                  {pending && <span className="loading loading-infinity loading-xs" />}
                </Button>
              </div>
            )}
          </TabsContent>
        ))}
      </Tabs>
    </>
  );
}
