import EmptyState from '@/components/EmptyState';
import { hideModal } from '@/components/Modal';
import { PageLoaderWithNav } from '@/components/PageLoaderWithNav';
import { TopNav } from '@/components/TopNav';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Form, FormItem, FormLabel } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useFdk, usePlainFdk } from '@/fdk';
import { Layout } from '@/Layout';
import { PlusIcon } from '@heroicons/react/20/solid';
import { DialogTrigger } from '@radix-ui/react-dialog';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import StationCard from './StationCard';

export function GuideList() {
  const { guideID } = useParams();
  const { t } = useTranslation('translation');
  const fdk = usePlainFdk();
  const navigate = useNavigate();

  const form = useForm({});
  const [createModal, setCreateModal] = useState(false);

  const { data: tour, isLoading } = useFdk({
    action: 'getEntry',
    model: 'guide_tours',
    entryID: guideID,
  });

  useEffect(() => {
    if (!createModal) {
      form.reset();
    }
  }, [createModal]);

  const submit = useCallback(
    async ({ title }: any) => {
      try {
        const station = await fdk.model('guide_tour_stations').createEntry({
          title: {
            de_DE: title,
          },
          description: {},
          audio: null,
          duration: null,
          tags: [],
          languages: 'de_DE',
        });
        await fdk.model('guide_tours').editEntry(guideID as string, {
          stations: [...tour.stations, station.id],
        });
        toast.success('Station erfolgreich erstellt!');
        return navigate(station.id, { state: { firstView: true } });
      } catch (err) {
        toast.error('Fehler beim Erstellen der Station!');
      } finally {
        hideModal('createModal');
      }
    },
    [guideID],
  );

  const { data: stationList, isLoading: isLoadingStations } = useFdk(
    tour
      ? {
          action: 'entryList',
          model: 'guide_tour_stations',
          options: {
            id: tour.stations,
          },
        }
      : null,
  );

  if (isLoading || isLoadingStations) {
    return <PageLoaderWithNav />;
  }

  return (
    <Layout>
      <TopNav label="Stationen">
        <Dialog open={createModal} onOpenChange={setCreateModal}>
          <DialogTrigger asChild>
            <Button onClick={() => setCreateModal(true)}>
              <PlusIcon className="w-5 h-5" /> Station erstellen
            </Button>
          </DialogTrigger>

          <DialogContent className="!bg-base-100">
            <DialogHeader>
              <DialogTitle>Station erstellen</DialogTitle>
            </DialogHeader>
            <DialogDescription asChild>
              <Form {...form}>
                <form onSubmit={form.handleSubmit(submit)}>
                  <div className="form-control mt-3">
                    <FormItem>
                      <FormLabel>Titel</FormLabel>
                      <Input
                        {...form.register('title', { required: true })}
                        autoComplete="off"
                        placeholder="Titel eintragen..."
                      />
                    </FormItem>
                  </div>

                  <div className="form-control">
                    <div className="flex items-end justify-end py-4 gap-x-4">
                      <DialogClose asChild>
                        <Button variant="secondary" type="reset">
                          abbrechen
                        </Button>
                      </DialogClose>
                      <Button type="submit" disabled={!form.formState.isValid}>
                        erstellen
                      </Button>
                    </div>
                  </div>
                </form>
              </Form>
            </DialogDescription>
          </DialogContent>
        </Dialog>
      </TopNav>
      <section className="card col-span-12 overflow- bg-base-100 shadow-sm xl:col-span-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {!stationList && Array.from(Array(5).keys()).map((i) => <StationCard.Skeleton key={i} />)}
        {!stationList?.length ? (
          stationList?.items?.map((station) => <StationCard key={station.id} station={station} tour={tour} />)
        ) : (
          <div className="col-span-3">
            <EmptyState
              description="Erstelle deine erste Station"
              error="Bisher gibt es keine Stationen"
              title="Station erstellen"
              onClick={() => setCreateModal(true)}
            />
          </div>
        )}
      </section>
    </Layout>
  );
}
