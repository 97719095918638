import EmptyState from '@/components/EmptyState';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Skeleton } from '@/components/ui/skeleton';
import { PlusIcon } from '@heroicons/react/20/solid';
import { ChevronsUpDown, X } from 'lucide-react';
import { createContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '../Layout';
import { TopNav } from '../components/TopNav';
import { VoiceCard } from '../components/VoiceCard';
import { useStudioApi } from '../hooks/useStudioAPI';
import { TAGS } from '../utils';
import { VoiceCreateModal } from './VoiceCreateModal';

export const VoiceContext = createContext<any>(null);

export default function Voices() {
  const [filter, setFilter] = useState(new Set());
  const playingCardRef = useRef(null);
  const { t, i18n } = useTranslation('translation');
  const { data: voices, mutate } = useStudioApi('voices');
  const { data: tags } = useStudioApi('tags');
  const [createModal, setCreateModal] = useState(false);
  const globalVoices = useMemo(() => voices?.filter(({ id }) => id.startsWith('global|')), [voices]);
  const localVoices = useMemo(() => voices?.filter(({ id }) => !id.startsWith('global|')), [voices]);

  const globalTags = useMemo(
    () =>
      //TODO: groupBy is not supported by Safari
      // Object.groupBy(tags?.filter(({ global }) => global) ?? [], ({ type }) => type),
      (tags?.filter(({ global }) => global) ?? []).reduce((acc, cur) => {
        if (!acc[cur.type]) {
          acc[cur.type] = [];
        }
        acc[cur.type].push(cur);
        return acc;
      }, {}),
    [tags],
  );

  return (
    <Layout>
      <VoiceContext.Provider value={{ playingCardRef }}>
        <TopNav>
          <div className="flex grow items-center justify-between">
            <div className="flex gap-x-20">
              <div>
                <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
                  {t('voices.headlineCloned')}
                </h1>
              </div>
            </div>
            <Dialog open={createModal} onOpenChange={setCreateModal}>
              <DialogTrigger asChild>
                <Button onClick={() => setCreateModal(true)}>
                  <PlusIcon className="w-5 h-5" /> {t('voices.addVoice')}
                </Button>
              </DialogTrigger>
              <DialogContent>
                <VoiceCreateModal
                  id="createModal"
                  onCreate={() => {
                    mutate();
                    setCreateModal(false);
                  }}
                />
              </DialogContent>
            </Dialog>
          </div>
        </TopNav>
        <div className="grid grid-cols-12 gap-2 mt-4" style={{ gridAutoRows: '1fr' }}>
          {!voices ? (
            <>
              {Array.from(Array(3).keys()).map((i) => (
                <VoiceCard.Skeleton key={i} />
              ))}
            </>
          ) : localVoices?.length > 0 ? (
            localVoices?.map((voice) => <VoiceCard key={voice.id} {...voice} />)
          ) : (
            <div className="col-span-12 mb-4">
              <EmptyState
                error={t('voices.noVoices')}
                description={t('voices.noVoiceDescription')}
                onClick={() => setCreateModal(true)}
                title={t('voices.addVoice')}
              />
            </div>
          )}
        </div>

        {globalVoices?.length > 0 && (
          <>
            <div className="flex grow items-center mt-4 justify-between col-span-full">
              <div>
                <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
                  {t('voices.headlineLibrary')}
                </h1>
              </div>
              <div className="flex gap-x-20">
                <div className="flex gap-x-4">
                  {Array.from(filter).length > 0 && (
                    <Button
                      onClick={() => setFilter(new Set())}
                      variant="outline"
                      className="flex gap-x-2 text-destructive/80"
                    >
                      <X className="w-4 h-4" />
                      {t('voices.clearFilter')}
                    </Button>
                  )}
                  {Object.entries(globalTags).length < 1 && (
                    <>
                      {Array.from(Array(4).keys()).map((i) => (
                        <Skeleton className="h-10 w-32 rounded-md border" key={i} />
                      ))}
                    </>
                  )}
                  {Object.entries(globalTags)
                    .sort(([a], [b]) => {
                      const preferredOrder = ['topic', 'gender', 'tone', b];

                      return preferredOrder.indexOf(a) - preferredOrder.indexOf(b);
                    })
                    .map(([type, tags]: any) => (
                      <DropdownMenu key={type}>
                        <DropdownMenuTrigger asChild>
                          <Button variant="outline">
                            {TAGS[i18n.language][type]} <ChevronsUpDown className=" ml-3 w-4 h-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          {tags?.map((tag) => (
                            <DropdownMenuCheckboxItem
                              key={tag.id}
                              checked={filter.has(tag.id)}
                              onCheckedChange={() => {
                                setFilter((old) => {
                                  const newFilter = new Set(old);
                                  if (newFilter.has(tag.id)) {
                                    newFilter.delete(tag.id);
                                  } else {
                                    newFilter.add(tag.id);
                                  }
                                  return newFilter;
                                });
                              }}
                            >
                              {tag.label}
                            </DropdownMenuCheckboxItem>
                          ))}
                        </DropdownMenuContent>
                      </DropdownMenu>
                    ))}
                </div>
              </div>
            </div>

            <div className="grid grid-cols-12 gap-2 mt-6" style={{ gridAutoRows: '1fr' }}>
              {!voices && (
                <>
                  {Array.from(Array(7).keys()).map((i) => (
                    <VoiceCard.Skeleton key={i} />
                  ))}
                </>
              )}
              {globalVoices
                ?.filter(({ tags }) => {
                  if (filter.size === 0) {
                    return true;
                  }
                  return Array.from(filter).every((id) => tags.some((tag) => tag.id === id));
                })
                .map((voice) => (
                  <VoiceCard key={voice.id} global {...voice} />
                ))}
            </div>
          </>
        )}
      </VoiceContext.Provider>
    </Layout>
  );
}
