import i18n from '@/i18n';
import { Content } from '@/routes/ChapterDetail';
import { clsx, type ClassValue } from 'clsx';
import dayjs from 'dayjs';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function secondsFormatter(value: number) {
  const result = dayjs.duration(value, 'seconds').humanize();
  return `${result.charAt(0).toUpperCase()}${result.slice(1)}`;
}

export const getUsedAudios = (content: Content) => {
  const usedAudios = content.sections.reduce((acc, cur) => {
    if (cur.type === 'file') {
      acc.push(cur.content);
    }
    return acc;
  }, [] as string[]);
  return usedAudios;
};

export const getUsedLocalVoices = (content: Content) => {
  const voices = content.sections.reduce((acc, cur) => {
    if (cur.voiceID && !cur.voiceID.includes('global')) {
      acc.push(cur.voiceID);
    }
    return acc;
  }, [] as string[]);

  if (content?.voiceID && !content.voiceID.includes('global')) {
    voices.push(content.voiceID);
  }
  return voices;
};

export function formatCurrency(value: number, currency = 'EUR') {
  const { language } = i18n;
  return new Intl.NumberFormat(language ?? 'de-DE', { style: 'currency', currency }).format(value / 100);
}
