import { useFdk, usePlainFdk } from '@/fdk';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { mutate as globalMutate } from 'swr';
import { SliderInput, SwitchInput } from './FormItems';
import { Button } from './ui/button';

export default function VoiceEditDialog({ name, id, setEdit }: { name: string; id: string; setEdit: any }) {
  const { t } = useTranslation('translation');
  const { shortID } = useParams();
  const fdk = usePlainFdk();
  const [pending, setPending] = useState(false);
  const { data: voice, mutate } = useFdk({
    model: 'voice_preset',
    action: 'getEntry',
    entryID: id,
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: voice?.config?.voice_settings,
  });

  useEffect(() => {
    if (voice?.config?.voice_settings) {
      const { style, stability, similarity_boost, use_speaker_boost } = voice.config.voice_settings;
      reset({
        style: Math.round(style * 100),
        stability: Math.round(stability * 100),
        similarity_boost: Math.round(similarity_boost * 100),
        use_speaker_boost,
      });
    }
  }, [voice]);

  if (!voice) {
    return (
      <div>
        <h3 className="font-bold text-lg">{name}</h3>
      </div>
    );
  }

  const onSubmit = async (data: any) => {
    try {
      setPending(true);
      const { style, stability, similarity_boost, use_speaker_boost } = data;
      await fdk.model('voice_preset').editEntry(id, {
        config: {
          ...voice.config,
          voice_settings: {
            style: style / 100,
            stability: stability / 100,
            similarity_boost: similarity_boost / 100,
            use_speaker_boost,
          },
        },
      });
      await mutate();
      // invalidate voices list to get new sample
      await globalMutate(`${import.meta.env.VITE_API_URL}/${shortID}/voices`);
      toast.success(t('voices.editVoiceSaveSuccess'));
    } catch (error) {
      toast.error(t('voices.editVoiceSaveError'));
    } finally {
      setPending(false);
      setEdit(false);
    }
  };

  return (
    <div>
      <h3 className="font-bold text-lg">{name}</h3>
      <span>{t('voices.editVoiceDescription')}</span>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 mt-4">
        <SliderInput label={t('voices.style')} control={control} name="style" min={0} max={100} step={1} unit="%" />
        <SliderInput
          label={t('voices.similarityBoost')}
          control={control}
          name="similarity_boost"
          min={0}
          max={100}
          step={1}
          unit="%"
        />
        <SliderInput
          label={t('voices.stability')}
          control={control}
          name="stability"
          min={0}
          max={100}
          step={1}
          unit="%"
        />
        <SwitchInput label={t('voices.useSpeakerBoost')} control={control} name="use_speaker_boost" />
        <Button className="!mt-8 flex items-center gap-1" type="submit" disabled={pending}>
          Speichern
          {pending && <span className="loading loading-infinity loading-xs" />}
        </Button>
      </form>
    </div>
  );
}
