import { usePlainFdk } from '@/fdk';
import { UserPlusIcon } from '@heroicons/react/24/outline';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { Button } from './ui/button';
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from './ui/dialog';
import { Form, FormItem, FormLabel } from './ui/form';
import { Input } from './ui/input';

export function Invites() {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('translation');
  const { shortID } = useParams<{ shortID: string }>();
  const fdk = usePlainFdk();
  const form = useForm();
  const { data: dataManagerID } = useSWR(`dataManagerID:${shortID}`, async () => {
    const { dataManagerID } = await fdk.subdomain('datamanager').route(`api/${shortID}`).raw();
    return dataManagerID;
  });
  const { data: account } = useSWR('account', async () => {
    const {
      _links: { 'ec:account': account },
    } = await fdk.subdomain('accounts').route('').raw();
    if (!account) {
      return null;
    }

    const { searchParams } = new URL(account.href);
    const accountID = searchParams.get('accountID');
    const res = await fdk.subdomain('accounts').route('account').raw({ accountID });

    return res;
  });

  const canInvite = useMemo(() => account?.permissions?.includes('acc:invite:createWithGroups'), [account]);

  const groups = useMemo(() => {
    return account?.groups.filter(
      ({ groupID, permissions }) =>
        groupID.startsWith('group:lizzen') && permissions.some((p) => p.startsWith(`dm:${dataManagerID}:`)),
    );
  }, [account, dataManagerID]);

  const submit = useCallback(
    async ({ email }: any) => {
      const {
        _embedded: { 'ec:invite': invite },
      } = await fdk
        .subdomain('accounts')
        .route('invite')
        .raw(
          {},
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              count: 1,
              email,
              permissions: [],
              groups,
            }),
          },
        );

      if (invite) {
        setOpen(false);
        const url = new URL(location.href);
        url.pathname = '/register';
        url.searchParams.set('invite', invite.invite);
        navigator.clipboard.writeText(url.toString());
        toast.success(t('invites.success'));
      } else {
        toast.error(t('invites.error'));
      }

      console.log(invite);
    },
    [groups],
  );

  useEffect(() => {
    form.reset();
  }, []);

  if (!canInvite) {
    return null;
  }

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger className="w-full" asChild>
        <Button variant="outline" className="gap-1">
          <UserPlusIcon className="w-5 h-5" /> {t('invites.add')}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>{t('invites.title')}</DialogTitle>
        <DialogDescription asChild>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(submit)}>
              <div className="form-control mt-3">
                <FormItem>
                  <FormLabel>E-Mail Adresse</FormLabel>
                  <Input
                    {...form.register('email', { required: true })}
                    type="email"
                    autoComplete="off"
                    placeholder="E-Mail Adresse eintragen..."
                  />
                </FormItem>
              </div>

              <div className="form-control">
                <div className="flex items-end justify-end py-4 gap-x-4">
                  <DialogClose asChild>
                    <Button variant="secondary" type="reset" disabled={form.formState.isSubmitting}>
                      abbrechen
                    </Button>
                  </DialogClose>
                  <Button type="submit" disabled={!form.formState.isValid || form.formState.isSubmitting}>
                    einladen
                  </Button>
                </div>
              </div>
            </form>
          </Form>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
}
